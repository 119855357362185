.form {
	display: flex;
	flex-direction: column;
	gap: 10px;
	background: linear-gradient(45deg, red, rgb(255, 0, 144));
	padding: 30px;
	width: 450px;
	border-radius: 20px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
	  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
	transition: background 0.3s ease;
	justify-content: center;
align-content: center;
margin: auto;
margin-bottom: 5%;
margin-top: 5%;
  }
 
 
  .form:hover {
	background: linear-gradient(45deg,rgb(255, 0, 144), red );
  }
  
  ::placeholder {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
	  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  
  .form button {
	align-self: flex-end;
  }
  
  .flex-column > label {
	color: #151717;
	font-weight: 600;
  }
  
  .inputForm {
	border: 1.5px solid #ecedec;
	border-radius: 10em;
	height: 50px;
	display: flex;
	align-items: center;
	padding-left: 10px;
	transition: 0.2s ease-in-out;
	background-color: white;
  }
  
  .input {
	margin-left: 10px;
	border-radius: 10rem;
	border: none;
	width: 100%;
	height: 100%;
  }
  
  .input:focus {
	outline: none;
  }
  
  .inputForm:focus-within {
	border: 1.5px solid #2d79f3;
  }
  
  .flex-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	justify-content: space-between;
  }
  
  .flex-row > div > label {
	font-size: 14px;
	color: black;
	font-weight: 400;
  }
  
  .span {
	font-size: 14px;
	margin-left: 5px;
	color: white;
	font-weight: 500;
	cursor: pointer;
  }
  
  .button-submit {
	position: relative;
	display: inline-block;
	padding: 15px 30px;
	text-align: center;
	letter-spacing: 1px;
	text-decoration: none;
	background: transparent;
	transition: ease-out 0.5s;
	border: 2px solid;
	border-radius: 10em;
	box-shadow: inset 0 0 0 0 red;
	margin: 20px 0 10px 0;
	color: white;
	font-size: 15px;
	font-weight: 500;
	height: 50px;
	width: 100%;
	cursor: pointer;
  }
  
  .button-submit:hover {
	color: white;
	box-shadow: inset 0 -100px 0 0 darkorange;
  }
  
  .button-submit:active {
	transform: scale(0.9);
  }
  
  .p {
	text-align: center;
	color: black;
	font-size: 14px;
	margin: 5px 0;
  }
  
  .btn-google {
	margin-top: 10px;
	width: 100%;
	height: 50px;
	border-radius: 10em;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	gap: 10px;
	border: 1px solid #ededef;
	background-color: white;
	cursor: pointer;
	transition: 0.2s ease-in-out;
  }
  
  .btn:hover {
	border: 1px solid #2d79f3;
  }
  
  .text-welcome{
	font-size: 20px;
	color: white;
	font-weight: 400;
  }